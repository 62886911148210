export const pageTypeObj = {
  /**
   * AP4012 APP
   * KF9025 400电话
   * GW8306 官网
   * GZ2293 公众号
   * HZ1256 展会
   * YJ7008 邮件
   * ZH1267 知乎内容
   * BJ7029 百家号
   * BD9153 百度
   * FB0901 facebook
   */
  // 4: 'YX0831', // 营销站
  4: 'HZ1031', // 营销站
  5: 'YA1824', // 营销站-阿联酋
  // 6: 'GT3461', // 营销站-港澳台
  6: 'HZ1031', // 营销站-港澳台- 变更
  7: 'TY0431' // 营销站-通用
}

export const allCountries = [
  {
    label: '+86',
    value: 86,
    name: '中国大陆',
    nameHant: '中國大陸',
    nameEn: 'China',
    nameEs: 'China continental',
    inputLimit: '11'
  },
  {
    label: '+852',
    value: 852,
    name: '中国香港',
    nameHant: '中国香港',
    nameEn: 'Hong Kong SAR, China',
    nameEs: 'Hong Kong, China',
    inputLimit: '8'
  },
  {
    label: '+853',
    value: 853,
    name: '中国澳门',
    nameHant: '中国澳门',
    nameEn: 'Macau SAR, China',
    nameEs: 'Macao, China',
    inputLimit: '8'
  },
  {
    label: '+886',
    value: 886,
    name: '中国台湾',
    nameHant: '中国台湾',
    nameEn: 'Taiwan, China',
    nameEs: 'Taiwán, China',
    inputLimit: '9'
  },
  {
    label: '+1',
    value: 1,
    name: '美国/加拿大',
    nameHant: '美國/加拿大',
    nameEn: 'US/Canada',
    nameEs: 'EE. UU./Canadá',
    inputLimit: '10'
  },
  {
    label: '+20',
    value: 20,
    name: '埃及',
    nameHant: '埃及',
    nameEn: 'Egypt',
    nameEs: 'Egipto',
    inputLimit: '10'
  },
  {
    label: '+27',
    value: 27,
    name: '南非',
    nameHant: '南非',
    nameEn: 'South Africa',
    nameEs: 'Sudáfrica',
    inputLimit: '9'
  },
  {
    label: '+30',
    value: 30,
    name: '希腊',
    nameHant: '希臘',
    nameEn: 'Greece',
    nameEs: 'Grecia',
    inputLimit: '10'
  },
  {
    label: '+31',
    value: 31,
    name: '荷兰',
    nameHant: '荷蘭',
    nameEn: 'Netherlands',
    nameEs: 'Países Bajos',
    inputLimit: '9'
  },
  {
    label: '+33',
    value: 33,
    name: '法国',
    nameHant: '法國',
    nameEn: 'France',
    nameEs: 'Francia',
    inputLimit: '9'
  },
  {
    label: '+34',
    value: 34,
    name: '西班牙',
    nameHant: '西班牙',
    nameEn: 'Spain',
    nameEs: 'España',
    inputLimit: '9'
  },
  {
    label: '+36',
    value: 36,
    name: '匈牙利',
    nameHant: '匈牙利',
    nameEn: 'Hungary',
    nameEs: 'Hungría',
    inputLimit: '9'
  },
  {
    label: '+39',
    value: 39,
    name: '意大利',
    nameHant: '意大利',
    nameEn: 'Italy',
    nameEs: 'Italia',
    inputLimit: '10'
  },
  {
    label: '+40',
    value: 40,
    name: '罗马尼亚',
    nameHant: '羅馬尼亞',
    nameEn: 'Romania',
    nameEs: 'Rumania',
    inputLimit: '9'
  },
  {
    label: '+41',
    value: 41,
    name: '瑞士',
    nameHant: '瑞士',
    nameEn: 'Switzerland',
    nameEs: 'Suiza',
    inputLimit: '9'
  },
  {
    label: '+44',
    value: 44,
    name: '英国',
    nameHant: '英國',
    nameEn: 'United Kingdom',
    nameEs: 'Reino Unido',
    inputLimit: '10'
  },
  {
    label: '+45',
    value: 45,
    name: '丹麦',
    nameHant: '丹麥',
    nameEn: 'Denmark',
    nameEs: 'Dinamarca',
    inputLimit: '8'
  },
  {
    label: '+46',
    value: 46,
    name: '瑞典',
    nameHant: '瑞典',
    nameEn: 'Sweden',
    nameEs: 'Suecia',
    inputLimit: '9'
  },
  {
    label: '+49',
    value: 49,
    name: '德国',
    nameHant: '德國',
    nameEn: 'Germany',
    nameEs: 'Alemania',
    inputLimit: '11'
  },
  {
    label: '+51',
    value: 51,
    name: '秘鲁',
    nameHant: '秘魯',
    nameEn: 'Peru',
    nameEs: 'Perú',
    inputLimit: '9'
  },
  {
    label: '+52',
    value: 52,
    name: '墨西哥',
    nameHant: '墨西哥',
    nameEn: 'Mexico',
    nameEs: 'México',
    inputLimit: '10'
  },
  {
    label: '+54',
    value: 54,
    name: '阿根廷',
    nameHant: '阿根廷',
    nameEn: 'Argentina',
    nameEs: 'Argentina',
    inputLimit: '10'
  },
  {
    label: '+55',
    value: 55,
    name: '巴西',
    nameHant: '巴西',
    nameEn: 'Brazil',
    nameEs: 'Brasil',
    inputLimit: '9'
  },
  {
    label: '+56',
    value: 56,
    name: '智利',
    nameHant: '智利',
    nameEn: 'Chile',
    nameEs: 'Chile',
    inputLimit: '9'
  },
  {
    label: '+57',
    value: 57,
    name: '哥伦比亚',
    nameHant: '哥倫比亞',
    nameEn: 'Columbia',
    nameEs: 'Colombia',
    inputLimit: '10'
  },
  {
    label: '+58',
    value: 58,
    name: '委内瑞拉',
    nameHant: '委內瑞拉',
    nameEn: 'Venezuela',
    nameEs: 'Venezuela',
    inputLimit: '10'
  },
  {
    label: '+60',
    value: 60,
    name: '马来西亚',
    nameHant: '馬來西亞',
    nameEn: 'Malaysia',
    nameEs: 'Malasia',
    inputLimit: '10'
  },
  {
    label: '+61',
    value: 61,
    name: '澳大利亚',
    nameHant: '澳大利亞',
    nameEn: 'Australia',
    nameEs: 'Australia',
    inputLimit: '9'
  },
  {
    label: '+62',
    value: 62,
    name: '印度尼西亚',
    nameHant: '印度尼西亞',
    nameEn: 'Indonesia',
    nameEs: 'Indonesia',
    inputLimit: '11'
  },
  {
    label: '+63',
    value: 63,
    name: '菲律宾',
    nameHant: '菲律賓',
    nameEn: 'Philippines',
    nameEs: 'Filipinas',
    inputLimit: '10'
  },
  {
    label: '+64',
    value: 64,
    name: '新西兰',
    nameHant: '新西蘭',
    nameEn: 'New Zealand',
    nameEs: 'Nueva Zelanda',
    inputLimit: '10'
  },
  {
    label: '+65',
    value: 65,
    name: '新加坡',
    nameHant: '新加坡',
    nameEn: 'Singapore',
    nameEs: 'Singapur',
    inputLimit: '8'
  },
  {
    label: '+66',
    value: 66,
    name: '泰国',
    nameHant: '泰國',
    nameEn: 'Thailand',
    nameEs: 'Tailandia',
    inputLimit: '9'
  },
  {
    label: '+81',
    value: 81,
    name: '日本',
    nameHant: '日本',
    nameEn: 'Japan',
    nameEs: 'Japón',
    inputLimit: '10'
  },
  {
    label: '+82',
    value: 82,
    name: '韩国',
    nameHant: '韓國',
    nameEn: 'Korea',
    nameEs: 'Corea',
    inputLimit: '10'
  },
  {
    label: '+84',
    value: 84,
    name: '越南',
    nameHant: '越南',
    nameEn: 'Vietnam',
    nameEs: 'Vietnam',
    inputLimit: '9'
  },
  {
    label: '+90',
    value: 90,
    name: '土耳其',
    nameHant: '土耳其',
    nameEn: 'Turkey',
    nameEs: 'Turquía',
    inputLimit: '10'
  },
  {
    label: '+91',
    value: 91,
    name: '印度',
    nameHant: '印度',
    nameEn: 'India',
    nameEs: 'India',
    inputLimit: '10'
  },
  {
    label: '+92',
    value: 92,
    name: '巴基斯坦',
    nameHant: '巴基斯坦',
    nameEn: 'Pakistan',
    nameEs: 'Pakistán',
    inputLimit: ''
  },
  {
    label: '+94',
    value: 94,
    name: '斯里兰卡',
    nameHant: '斯裏蘭卡',
    nameEn: 'Sri Lanka',
    nameEs: 'Sri Lanka',
    inputLimit: '9'
  },
  {
    label: '+212',
    value: 212,
    name: '摩洛哥',
    nameHant: '摩洛哥',
    nameEn: 'Morocco',
    nameEs: 'Marruecos',
    inputLimit: '9'
  },
  {
    label: '+213',
    value: 213,
    name: '阿尔及利亚',
    nameHant: '阿爾及利亞',
    nameEn: 'Algeria',
    nameEs: 'Argelia',
    inputLimit: '9'
  },
  {
    label: '+216',
    value: 216,
    name: '突尼斯',
    nameHant: '突尼斯',
    nameEn: 'Tunisia',
    nameEs: 'Túnez',
    inputLimit: '8'
  },
  {
    label: '+220',
    value: 220,
    name: '冈比亚',
    nameHant: '岡比亞',
    nameEn: 'Gambia',
    nameEs: 'Gambia',
    inputLimit: '7'
  },
  {
    label: '+221',
    value: 221,
    name: '塞内加尔',
    nameHant: '塞內加爾',
    nameEn: 'Senegal',
    nameEs: 'Senegal',
    inputLimit: '7'
  },
  {
    label: '+222',
    value: 222,
    name: '毛里塔尼亚',
    nameHant: '毛里塔尼亞',
    nameEn: 'Mauritania',
    nameEs: 'Mauritania',
    inputLimit: '8'
  },
  {
    label: '+224',
    value: 224,
    name: '几内亚',
    nameHant: '幾內亞',
    nameEn: 'Guinea',
    nameEs: 'Guinea',
    inputLimit: '9'
  },
  {
    label: '+225',
    value: 225,
    name: '科特迪瓦',
    nameHant: '科特迪瓦',
    nameEn: 'Ivory Coast',
    nameEs: 'Costa de Marfil',
    inputLimit: '8'
  },
  {
    label: '+226',
    value: 226,
    name: '布基纳法索',
    nameHant: '布基納法索',
    nameEn: 'Burkina Faso',
    nameEs: 'Burkina Faso',
    inputLimit: '8'
  },
  {
    label: '+227',
    value: 227,
    name: '尼日尔',
    nameHant: '尼日爾',
    nameEn: 'Niger',
    nameEs: 'Níger',
    inputLimit: '8'
  },
  {
    label: '+228',
    value: 228,
    name: '多哥',
    nameHant: '多哥',
    nameEn: 'Togo',
    nameEs: 'Togo',
    inputLimit: '8'
  },
  {
    label: '+229',
    value: 229,
    name: '贝宁',
    nameHant: '貝寧',
    nameEn: 'Benin',
    nameEs: 'Benín',
    inputLimit: '8'
  },
  {
    label: '+230',
    value: 230,
    name: '毛里求斯',
    nameHant: '毛裏求斯',
    nameEn: 'Mauritius',
    nameEs: 'Mauricio',
    inputLimit: '8'
  },
  {
    label: '+231',
    value: 231,
    name: '利比里亚',
    nameHant: '利比裏亞',
    nameEn: 'Liberia',
    nameEs: 'Liberia',
    inputLimit: '9'
  },
  {
    label: '+232',
    value: 232,
    name: '塞拉利昂',
    nameHant: '塞拉利昂',
    nameEn: 'Sierra Leone',
    nameEs: 'Sierra Leona',
    inputLimit: '8'
  },
  {
    label: '+233',
    value: 233,
    name: '加纳',
    nameHant: '加納',
    nameEn: 'Ghana',
    nameEs: 'Ghana',
    inputLimit: '9'
  },
  {
    label: '+234',
    value: 234,
    name: '尼日利亚',
    nameHant: '尼日利亞',
    nameEn: 'Nigeria',
    nameEs: 'Nigeria',
    inputLimit: '10'
  },
  {
    label: '+235',
    value: 235,
    name: '乍得',
    nameHant: '乍得',
    nameEn: 'Chad',
    nameEs: 'Chad',
    inputLimit: '8'
  },
  {
    label: '+237',
    value: 237,
    name: '喀麦隆',
    nameHant: '喀麥隆',
    nameEn: 'Cameroon',
    nameEs: 'Camerún',
    inputLimit: '9'
  },
  {
    label: '+238',
    value: 238,
    name: '佛得角',
    nameHant: '佛得角',
    nameEn: 'Cape Verde',
    nameEs: 'Cabo Verde',
    inputLimit: '7'
  },
  {
    label: '+239',
    value: 239,
    name: '圣多美和普林西比',
    nameHant: '聖多美和普林西比',
    nameEn: 'Sao Tome and Principe',
    nameEs: 'Sao Tome and Principe',
    inputLimit: '7'
  },
  {
    label: '+240',
    value: 240,
    name: '赤道几内亚',
    nameHant: '赤道幾內亞',
    nameEn: 'Equatorial Guinea',
    nameEs: 'Guinea Ecuatorial',
    inputLimit: '9'
  },
  {
    label: '+241',
    value: 241,
    name: '加蓬',
    nameHant: '加蓬',
    nameEn: 'Gabon',
    nameEs: 'Gabón',
    inputLimit: '8'
  },
  {
    label: '+242',
    value: 242,
    name: '刚果',
    nameHant: '剛果',
    nameEn: 'Congo',
    nameEs: 'Congo',
    inputLimit: '9'
  },
  {
    label: '+244',
    value: 244,
    name: '安哥拉',
    nameHant: '安哥拉',
    nameEn: 'Angola',
    nameEs: 'Angola',
    inputLimit: '9'
  },
  {
    label: '+247',
    value: 247,
    name: '阿森松',
    nameHant: '阿森松',
    nameEn: 'Ascension',
    nameEs: 'Ascensión',
    inputLimit: '8'
  },
  {
    label: '+248',
    value: 248,
    name: '塞舌尔',
    nameHant: '塞舌爾',
    nameEn: 'Seychelles',
    nameEs: 'Seychelles',
    inputLimit: '7'
  },
  {
    label: '+250',
    value: 250,
    name: '卢旺达',
    nameHant: '盧旺達',
    nameEn: 'Rwanda',
    nameEs: 'Ruanda',
    inputLimit: '9'
  },
  {
    label: '+251',
    value: 251,
    name: '埃塞俄比亚',
    nameHant: '埃塞俄比亞',
    nameEn: 'Ethiopia',
    nameEs: 'Etiopía',
    inputLimit: '9'
  },
  {
    label: '+253',
    value: 253,
    name: '吉布提',
    nameHant: '吉布提',
    nameEn: 'Djibouti',
    nameEs: 'Yibuti',
    inputLimit: '8'
  },
  {
    label: '+254',
    value: 254,
    name: '肯尼亚',
    nameHant: '肯尼亞',
    nameEn: 'Kenya',
    nameEs: 'Kenia',
    inputLimit: '9'
  },
  {
    label: '+255',
    value: 255,
    name: '坦桑尼亚',
    nameHant: '坦桑尼亞',
    nameEn: 'Tanzania',
    nameEs: 'Tanzania',
    inputLimit: '9'
  },
  {
    label: '+256',
    value: 256,
    name: '乌干达',
    nameHant: '烏幹達',
    nameEn: 'Uganda',
    nameEs: 'Uganda',
    inputLimit: '9'
  },
  {
    label: '+258',
    value: 258,
    name: '莫桑比克',
    nameHant: '莫桑比克',
    nameEn: 'Mozambique',
    nameEs: 'Mozambique',
    inputLimit: '9'
  },
  {
    label: '+260',
    value: 260,
    name: '赞比亚',
    nameHant: '贊比亞',
    nameEn: 'Zambia',
    nameEs: 'Zambia',
    inputLimit: '9'
  },
  {
    label: '+261',
    value: 261,
    name: '马达加斯加',
    nameHant: '馬達加斯加',
    nameEn: 'Madagascar',
    nameEs: 'Madagascar',
    inputLimit: '9'
  },
  {
    label: '+262',
    value: 262,
    name: '留尼旺',
    nameHant: '留尼旺',
    nameEn: 'Reunion',
    nameEs: 'Reunión',
    inputLimit: '9'
  },
  {
    label: '+264',
    value: 264,
    name: '纳米比亚',
    nameHant: '納米比亞',
    nameEn: 'Namibia',
    nameEs: 'Namibia',
    inputLimit: '9'
  },
  {
    label: '+265',
    value: 265,
    name: '马拉维',
    nameHant: '馬拉維',
    nameEn: 'Malawi',
    nameEs: 'Malaui',
    inputLimit: '9'
  },
  {
    label: '+266',
    value: 266,
    name: '莱索托',
    nameHant: '萊索托',
    nameEn: 'Lesotho',
    nameEs: 'Lesoto',
    inputLimit: '8'
  },
  {
    label: '+267',
    value: 267,
    name: '博茨瓦纳',
    nameHant: '博茨瓦納',
    nameEn: 'Botswana',
    nameEs: 'Botsuana',
    inputLimit: '8'
  },
  {
    label: '+268',
    value: 268,
    name: '斯威士兰',
    nameHant: '斯威士蘭',
    nameEn: 'Swaziland',
    nameEs: 'Swazilandia',
    inputLimit: '8'
  },
  {
    label: '+269',
    value: 269,
    name: '科摩罗',
    nameHant: '科摩羅',
    nameEn: 'Comoros',
    nameEs: 'Comoras',
    inputLimit: '7'
  },
  {
    label: '+297',
    value: 297,
    name: '阿鲁巴',
    nameHant: '阿魯巴',
    nameEn: 'Aruba',
    nameEs: 'aruba',
    inputLimit: '7'
  },
  {
    label: '+298',
    value: 298,
    name: '格陵兰岛',
    nameHant: '格陵蘭島',
    nameEn: 'Greenland',
    nameEs: 'Groenlandia',
    inputLimit: '6'
  },
  {
    label: '+299',
    value: 299,
    name: '格陵兰岛',
    nameHant: '法羅群島',
    nameEn: 'Faroe Islands',
    nameEs: 'Islas Faroe',
    inputLimit: '6'
  },
  {
    label: '+350',
    value: 350,
    name: '直布罗陀',
    nameHant: '直布羅陀',
    nameEn: 'Gibraltar',
    nameEs: 'Gibraltar',
    inputLimit: '8'
  },
  {
    label: '+351',
    value: 351,
    name: '葡萄牙',
    nameHant: '葡萄牙',
    nameEn: 'Portugal',
    nameEs: 'Portugal',
    inputLimit: '9'
  },
  {
    label: '+352',
    value: 352,
    name: '卢森堡',
    nameHant: '盧森堡',
    nameEn: 'Luxembourg',
    nameEs: 'luxemburgo',
    inputLimit: '9'
  },
  {
    label: '+353',
    value: 353,
    name: '爱尔兰',
    nameHant: '愛爾蘭',
    nameEn: 'Ireland',
    nameEs: 'Irlanda',
    inputLimit: '9'
  },
  {
    label: '+354',
    value: 354,
    name: '冰岛',
    nameHant: '冰島',
    nameEn: 'Iceland',
    nameEs: 'Islandia',
    inputLimit: '7'
  },
  {
    label: '+355',
    value: 355,
    name: '阿尔巴尼亚',
    nameHant: '阿爾巴尼亞',
    nameEn: 'Albania',
    nameEs: 'Albania',
    inputLimit: '9'
  },
  {
    label: '+356',
    value: 356,
    name: '马耳他',
    nameHant: '馬耳他',
    nameEn: 'Malta',
    nameEs: 'Malta',
    inputLimit: '8'
  },
  {
    label: '+357',
    value: 357,
    name: '塞浦路斯',
    nameHant: '塞浦路斯',
    nameEn: 'Cyprus',
    nameEs: 'Chipre',
    inputLimit: '8'
  },
  {
    label: '+358',
    value: 358,
    name: '芬兰',
    nameHant: '芬蘭',
    nameEn: 'Finland',
    nameEs: 'Finlandia',
    inputLimit: '9'
  },
  {
    label: '+359',
    value: 359,
    name: '保加利亚',
    nameHant: '保加利亞',
    nameEn: 'Bulgaria',
    nameEs: 'Bulgaria',
    inputLimit: '9'
  },
  {
    label: '+370',
    value: 370,
    name: '立陶宛',
    nameHant: '立陶宛',
    nameEn: 'Lituania',
    nameEs: 'Lithuania',
    inputLimit: '8'
  },
  {
    label: '+371',
    value: 371,
    name: '拉脱维亚',
    nameHant: '拉脫維亞',
    nameEn: 'Latvia',
    nameEs: 'letonia',
    inputLimit: '8'
  },
  {
    label: '+372',
    value: 372,
    name: '爱沙尼亚',
    nameHant: '愛沙尼亞',
    nameEn: 'Estonia',
    nameEs: 'Estonia',
    inputLimit: '8'
  },
  {
    label: '+373',
    value: 373,
    name: '摩尔多瓦',
    nameHant: '摩爾多瓦',
    nameEn: 'Moldova',
    nameEs: 'Moldavia',
    inputLimit: '8'
  },
  {
    label: '+374',
    value: 374,
    name: '亚美尼亚',
    nameHant: '亞美尼亞',
    nameEn: 'Armenia',
    nameEs: 'Armenia',
    inputLimit: '8'
  },
  {
    label: '+376',
    value: 376,
    name: '安道尔共和国',
    nameHant: '安道爾共和國',
    nameEn: 'Andorra Republic',
    nameEs: 'República de Andorra',
    inputLimit: '9'
  },
  {
    label: '+377',
    value: 377,
    name: '摩纳哥',
    nameHant: '摩纳哥',
    nameEn: 'Monaco',
    nameEs: 'Mónaco',
    inputLimit: '8'
  },
  {
    label: '+378',
    value: 378,
    name: '圣马力诺',
    nameHant: '聖馬力諾',
    nameEn: 'San Marino',
    nameEs: 'San Marino',
    inputLimit: '8'
  },
  {
    label: '+381',
    value: 381,
    name: '塞尔维亚',
    nameHant: '塞爾維亞',
    nameEn: 'Serbia',
    nameEs: 'Serbia',
    inputLimit: '9'
  },
  {
    label: '+382',
    value: 382,
    name: '黑山',
    nameHant: '黑山',
    nameEn: 'Montenegro',
    nameEs: 'montenegro',
    inputLimit: '8'
  },
  {
    label: '+385',
    value: 385,
    name: '克罗地亚',
    nameHant: '克羅地亞',
    nameEn: 'Croatia',
    nameEs: 'Croacia',
    inputLimit: '9'
  },
  {
    label: '+386',
    value: 386,
    name: '斯洛文尼亚',
    nameHant: '斯洛文尼亞',
    nameEn: 'Slovenia',
    nameEs: 'Eslovenia',
    inputLimit: '8'
  },
  {
    label: '+387',
    value: 387,
    name: '波斯尼亚和黑塞哥维那',
    nameHant: '波斯尼亞和黑塞哥維那',
    nameEn: 'Bosnia and Herzegovina',
    nameEs: 'Bosnia y Herzegovina',
    inputLimit: '9'
  },
  {
    label: '+389',
    value: 389,
    name: '北马其顿',
    nameHant: '北馬其頓',
    nameEn: 'North Macedonia',
    nameEs: 'macedonia del norte',
    inputLimit: '8'
  },
  {
    label: '+420',
    value: 420,
    name: '捷克',
    nameHant: '捷克',
    nameEn: 'Czechia',
    nameEs: 'Chequia',
    inputLimit: '9'
  },
  {
    label: '+421',
    value: 421,
    name: '斯洛伐克',
    nameHant: '斯洛伐克',
    nameEn: 'Slovakia',
    nameEs: 'Eslovaquia',
    inputLimit: '9'
  },
  {
    label: '+423',
    value: 423,
    name: '列支敦士登',
    nameHant: '列支敦士登',
    nameEn: 'Liechtenstein',
    nameEs: 'Liechtenstein',
    inputLimit: '7'
  },
  {
    label: '+501',
    value: 501,
    name: '伯利兹',
    nameHant: '伯利兹',
    nameEn: 'Belize',
    nameEs: 'Belice',
    inputLimit: '7'
  },
  {
    label: '+502',
    value: 502,
    name: '危地马拉',
    nameHant: '危地馬拉',
    nameEn: 'Guatemala',
    nameEs: 'Guatemala',
    inputLimit: '8'
  },
  {
    label: '+503',
    value: 503,
    name: '萨尔瓦多',
    nameHant: '薩爾瓦多',
    nameEn: 'El Salvador',
    nameEs: 'El Salvador',
    inputLimit: '8'
  },
  {
    label: '+504',
    value: 504,
    name: '洪都拉斯',
    nameHant: '洪都拉斯',
    nameEn: 'Honduras',
    nameEs: 'Honduras',
    inputLimit: '8'
  },
  {
    label: '+505',
    value: 505,
    name: '尼加拉瓜',
    nameHant: '尼加拉瓜',
    nameEn: 'Nicaragua',
    nameEs: 'Nicaragua',
    inputLimit: '8'
  },
  {
    label: '+506',
    value: 506,
    name: '哥斯达黎加',
    nameHant: '哥斯達黎加',
    nameEn: 'Costa Rica',
    nameEs: 'Costa Rica',
    inputLimit: '8'
  },
  {
    label: '+507',
    value: 507,
    name: '巴拿马',
    nameHant: '巴拿马',
    nameEn: 'Panama',
    nameEs: 'Panamá',
    inputLimit: '8'
  },
  {
    label: '+509',
    value: 509,
    name: '海地',
    nameHant: '海地',
    nameEn: 'Haiti',
    nameEs: 'Haití',
    inputLimit: '8'
  },
  {
    label: '+508',
    value: 508,
    name: '圣彼埃尔和密克隆岛',
    nameHant: '聖彼埃爾和密克隆島',
    nameEn: 'San Pedro y Miquelón',
    nameEs: 'San Pedro y Miquelón',
    inputLimit: '6'
  },
  {
    label: '+590',
    value: 590,
    name: '瓜德罗普岛',
    nameHant: '瓜德羅普島',
    nameEn: 'Guadeloupe',
    nameEs: 'Guadalupe',
    inputLimit: '9'
  },
  {
    label: '+591',
    value: 591,
    name: '玻利维亚',
    nameHant: '玻利維亞',
    nameEn: 'Bolivia',
    nameEs: 'Bolivia',
    inputLimit: '8'
  },
  {
    label: '+592',
    value: 592,
    name: '圭亚那',
    nameHant: '圭亞那',
    nameEn: 'Guyana',
    nameEs: 'Guyana',
    inputLimit: '7'
  },
  {
    label: '+593',
    value: 593,
    name: '厄瓜多尔',
    nameHant: '厄瓜多爾',
    nameEn: 'Ecuador',
    nameEs: 'Ecuador',
    inputLimit: '7'
  },
  {
    label: '+594',
    value: 594,
    name: '法属圭亚那',
    nameHant: '法屬圭亞那',
    nameEn: 'French Guiana',
    nameEs: 'Guayana Francesa',
    inputLimit: '9'
  },
  {
    label: '+595',
    value: 595,
    name: '巴拉圭',
    nameHant: '巴拉圭',
    nameEn: 'Paraguay',
    nameEs: 'Paraguay',
    inputLimit: '8'
  },
  {
    label: '+596',
    value: 596,
    name: '马提尼克',
    nameHant: '馬提尼克',
    nameEn: 'Martinique',
    nameEs: 'Martinica',
    inputLimit: '9'
  },
  {
    label: '+597',
    value: 597,
    name: '苏里南',
    nameHant: '蘇裏南',
    nameEn: 'Suriname',
    nameEs: 'Surinam',
    inputLimit: '7'
  },
  {
    label: '+598',
    value: 598,
    name: '乌拉圭',
    nameHant: '烏拉圭',
    nameEn: 'Uruguay',
    nameEs: 'Uruguay',
    inputLimit: '8'
  },
  {
    label: '+599',
    value: 599,
    name: '库拉索',
    nameHant: '庫拉索',
    nameEn: 'Curacao',
    nameEs: 'curazao',
    inputLimit: '7'
  },
  {
    label: '+670',
    value: 670,
    name: '东帝汶',
    nameHant: '東帝汶',
    nameEn: 'Timor-Leste',
    nameEs: 'Timor Oriental',
    inputLimit: '8'
  },
  {
    label: '+673',
    value: 673,
    name: '文莱',
    nameHant: '文萊',
    nameEn: 'Brunei',
    nameEs: 'Brunéi',
    inputLimit: '7'
  },
  {
    label: '+674',
    value: 674,
    name: '瑙鲁',
    nameHant: '瑙鲁',
    nameEn: 'Nauru',
    nameEs: 'Nauru',
    inputLimit: '7'
  },
  {
    label: '+675',
    value: 675,
    name: '巴布亚新几内亚',
    nameHant: '巴布亞新幾內亞',
    nameEn: 'Papua New Guinea',
    nameEs: 'Papúa Nueva Guinea',
    inputLimit: '8'
  },
  {
    label: '+676',
    value: 676,
    name: '汤加',
    nameHant: '湯加',
    nameEn: 'Tonga',
    nameEs: 'Tonga',
    inputLimit: '7'
  },
  {
    label: '+677',
    value: 677,
    name: '所罗门群岛',
    nameHant: '所羅門群島',
    nameEn: 'Solomon Islands',
    nameEs: 'Islas Salomón',
    inputLimit: '7'
  },
  {
    label: '+678',
    value: 678,
    name: '瓦努阿图',
    nameHant: '瓦努阿圖',
    nameEn: 'Vanuatu',
    nameEs: 'Vanuatu',
    inputLimit: '7'
  },
  {
    label: '+679',
    value: 679,
    name: '斐济',
    nameHant: '斐濟',
    nameEn: 'Fiji',
    nameEs: 'Fiyi',
    inputLimit: '7'
  },
  {
    label: '+680',
    value: 680,
    name: '帕劳',
    nameHant: '帕勞',
    nameEn: 'Palau',
    nameEs: 'palaos',
    inputLimit: '7'
  },
  {
    label: '+682',
    value: 682,
    name: '库克群岛',
    nameHant: '庫克群島',
    nameEn: 'Cook Islands',
    nameEs: 'Islas Cook',
    inputLimit: '5'
  },
  {
    label: '+684',
    value: 684,
    name: '东萨摩亚(美)',
    nameHant: '東薩摩亞(美)',
    nameEn: 'Eastern Samoa (US)',
    nameEs: 'Samoa Oriental (EE. UU.)',
    inputLimit: '10'
  },
  {
    label: '+685',
    value: 685,
    name: '萨摩亚',
    nameHant: '薩摩亞',
    nameEn: 'Samoa',
    nameEs: 'samoa',
    inputLimit: '7'
  },
  {
    label: '+686',
    value: 686,
    name: '基里巴斯',
    nameHant: '基里巴斯',
    nameEn: 'Kiribati',
    nameEs: 'Kiribati',
    inputLimit: '7'
  },
  {
    label: '+687',
    value: 687,
    name: '新喀里多尼亚',
    nameHant: '新喀裡多尼亞',
    nameEn: 'New Caledonia',
    nameEs: 'Nueva Caledonia',
    inputLimit: '6'
  },
  {
    label: '+689',
    value: 689,
    name: '法属玻利尼西亚',
    nameHant: '法屬玻利尼西亞',
    nameEn: 'French Polynesia',
    nameEs: 'Polinesia Frances',
    inputLimit: '8'
  },
  {
    label: '+855',
    value: 855,
    name: '柬埔寨',
    nameHant: '柬埔寨',
    nameEn: 'Cambodia',
    nameEs: 'Camboya',
    inputLimit: '9'
  },
  {
    label: '+856',
    value: 856,
    name: '老挝',
    nameHant: '老撾',
    nameEn: 'Laos',
    nameEs: 'Laos',
    inputLimit: '10'
  },
  {
    label: '+880',
    value: 880,
    name: '孟加拉国',
    nameHant: '孟加拉國',
    nameEn: 'Bangladesh',
    nameEs: 'Bangladesh',
    inputLimit: '8'
  },
  {
    label: '+960',
    value: 960,
    name: '马尔代夫',
    nameHant: '馬爾代夫',
    nameEn: 'Maldives',
    nameEs: 'Maldivas',
    inputLimit: '7'
  },
  {
    label: '+962',
    value: 962,
    name: '约旦',
    nameHant: '約旦',
    nameEn: 'Jordan',
    nameEs: 'Jordania',
    inputLimit: '9'
  },
  {
    label: '+965',
    value: 965,
    name: '科威特',
    nameHant: '科威特',
    nameEn: 'Kuwait',
    nameEs: 'Kuwait',
    inputLimit: '8'
  },
  {
    label: '+966',
    value: 966,
    name: '沙特阿拉伯',
    nameHant: '沙特阿拉伯',
    nameEn: 'Saudi Arabia',
    nameEs: 'Arabia Saudita',
    inputLimit: '9'
  },
  {
    label: '+968',
    value: 968,
    name: '阿曼',
    nameHant: '阿曼',
    nameEn: 'Oman',
    nameEs: 'Omán',
    inputLimit: '8'
  },
  {
    label: '+970',
    value: 970,
    name: '巴勒斯坦',
    nameHant: '巴勒斯坦',
    nameEn: 'Palestine',
    nameEs: 'Palestina',
    inputLimit: '9'
  },
  {
    label: '+971',
    value: 971,
    name: '阿拉伯联合酋长国',
    nameHant: '阿拉伯聯合酋長國',
    nameEn: 'United Arab Emirates',
    nameEs: 'Emiratos Árabes Unidos',
    inputLimit: '9'
  },
  {
    label: '+972',
    value: 972,
    name: '以色列',
    nameHant: '以色列',
    nameEn: 'Israel',
    nameEs: 'Israel',
    inputLimit: '9'
  },
  {
    label: '+973',
    value: 973,
    name: '巴林',
    nameHant: '巴林',
    nameEn: 'Bahrain',
    nameEs: 'Bahréin',
    inputLimit: '8'
  },
  {
    label: '+974',
    value: 974,
    name: '卡塔尔',
    nameHant: '卡塔爾',
    nameEn: 'Qatar',
    nameEs: 'Qata',
    inputLimit: '8'
  },
  {
    label: '+975',
    value: 975,
    name: '不丹',
    nameHant: '不丹',
    nameEn: 'Bhutan',
    nameEs: 'Bután',
    inputLimit: '8'
  },
  {
    label: '+976',
    value: 976,
    name: '蒙古',
    nameHant: '蒙古',
    nameEn: 'Mongolia',
    nameEs: 'Mongolia',
    inputLimit: '8'
  },
  {
    label: '+977',
    value: 977,
    name: '尼泊尔',
    nameHant: '尼泊爾',
    nameEn: 'Nepal',
    nameEs: 'Nepal',
    inputLimit: '10'
  },
  {
    label: '+992',
    value: 992,
    name: '塔吉克斯坦',
    nameHant: '塔吉克斯坦',
    nameEn: 'Tajikistan',
    nameEs: 'Tayikistán',
    inputLimit: '9'
  },
  {
    label: '+993',
    value: 993,
    name: '土库曼斯坦',
    nameHant: '土庫曼斯坦',
    nameEn: 'Turkmenistan',
    nameEs: 'Turkmenistán',
    inputLimit: '8'
  },
  {
    label: '+994',
    value: 994,
    name: '阿塞拜疆',
    nameHant: '阿塞拜疆',
    nameEn: 'Azerbaijan',
    nameEs: 'Azerbaiyán',
    inputLimit: '9'
  },
  {
    label: '+995',
    value: 995,
    name: '格鲁吉亚',
    nameHant: '格魯吉亞',
    nameEn: 'Georgia',
    nameEs: 'Georgia',
    inputLimit: '9'
  },
  {
    label: '+996',
    value: 996,
    name: '吉尔吉斯坦',
    nameHant: '吉爾吉斯坦',
    nameEn: 'Kyrgyzstan',
    nameEs: 'Kirguistán',
    inputLimit: '9'
  },
  {
    label: '+997',
    value: 997,
    name: '哈萨克斯坦',
    nameHant: '哈薩克斯坦',
    nameEn: 'Kazakhstan',
    nameEs: 'Kazajstán',
    inputLimit: '10'
  },
  {
    label: '+998',
    value: 998,
    name: '乌兹别克斯坦',
    nameHant: '烏茲別克斯坦',
    nameEn: 'Uzbekistan',
    nameEs: 'Kirguistán',
    inputLimit: '9'
  },
  {
    label: '+1242',
    value: 1242,
    name: '巴哈马',
    nameHant: '巴哈馬',
    nameEn: 'Bahamas',
    nameEs: 'Bahamas',
    inputLimit: '7'
  },
  {
    label: '+1246',
    value: 1246,
    name: '巴巴多斯',
    nameHant: '巴巴多斯',
    nameEn: 'Barbados',
    nameEs: 'Barbados',
    inputLimit: '7'
  },
  {
    label: '+1264',
    value: 1264,
    name: '安圭拉岛',
    nameHant: '安圭拉島',
    nameEn: 'Anguilla',
    nameEs: 'Anguila',
    inputLimit: '7'
  },
  {
    label: '+1268',
    value: 1268,
    name: '安提瓜和巴布达',
    nameHant: '安提瓜和巴布達',
    nameEn: 'Antigua and Barbuda',
    nameEs: 'Antigua y Barbuda',
    inputLimit: '7'
  },
  {
    label: '+1284',
    value: 1284,
    name: '英属维尔京群岛',
    nameHant: '英屬維爾京群島',
    nameEn: 'Islas Vírgenes Británicas',
    nameEs: 'Antigua y Barbuda',
    inputLimit: '7'
  },
  {
    label: '+1340',
    value: 1340,
    name: '美属维尔京群岛',
    nameHant: '美屬維爾京群島',
    nameEn: 'Virgin Islands, US',
    nameEs: 'Islas Vírgenes de los Estados Unidos',
    inputLimit: '7'
  },
  {
    label: '+1345',
    value: 1345,
    name: '开曼群岛',
    nameHant: '開曼群島',
    nameEn: 'Cayman Islands',
    nameEs: 'Islas Caimán',
    inputLimit: '7'
  },
  {
    label: '+1441',
    value: 1441,
    name: '百慕大群岛',
    nameHant: '百慕大群島',
    nameEn: 'Bermuda',
    nameEs: 'Bermudas',
    inputLimit: '7'
  },
  {
    label: '+1473',
    value: 1473,
    name: '格林纳达',
    nameHant: '格林納達',
    nameEn: 'Grenada',
    nameEs: 'Granada',
    inputLimit: '7'
  },
  {
    label: '+1649',
    value: 1649,
    name: '特克斯和凯科斯群岛',
    nameHant: '特克斯和凱科斯群島',
    nameEn: 'Turks and Caicos Islands',
    nameEs: 'Islas Turcas y Caicos',
    inputLimit: '7'
  },
  {
    label: '+1664',
    value: 1664,
    name: '蒙特塞拉特岛',
    nameHant: '蒙特塞拉特島',
    nameEn: 'Montserrat',
    nameEs: 'Monserrat',
    inputLimit: '7'
  },
  {
    label: '+1670',
    value: 1670,
    name: '马里亚那群岛',
    nameHant: '馬裏亞那群島',
    nameEn: 'Mariana Islands',
    nameEs: 'Islas Marianas',
    inputLimit: '11'
  },
  {
    label: '+1671',
    value: 1671,
    name: '关岛',
    nameHant: '關島',
    nameEn: 'Guam',
    nameEs: 'Guam',
    inputLimit: '7'
  },
  {
    label: '+1684',
    value: 1684,
    name: '美属萨摩亚',
    nameHant: '美屬薩摩亞',
    nameEn: 'American Samoa',
    nameEs: 'Samoa Americana',
    inputLimit: '7'
  },
  {
    label: '+1721',
    value: 1721,
    name: '圣马丁岛（荷兰部分）',
    nameHant: '聖馬丁島（荷蘭部分）',
    nameEn: 'Sint Maarten (Dutch Part)',
    nameEs: 'Sint Maarten (parte holandesa)',
    inputLimit: '7'
  },
  {
    label: '+1758',
    value: 1758,
    name: '圣卢西亚',
    nameHant: '聖盧西亞',
    nameEn: 'Saint Lucia',
    nameEs: 'Santa Lucía',
    inputLimit: '7'
  },
  {
    label: '+1767',
    value: 1767,
    name: '多米尼克',
    nameHant: '多米尼克',
    nameEn: 'Dominica',
    nameEs: 'dominicana',
    inputLimit: '7'
  },
  {
    label: '+1784',
    value: 1784,
    name: '圣文森特和格林纳丁斯',
    nameHant: '聖文森特和格林納丁斯',
    nameEn: 'Saint Vincent and The Grenadines',
    nameEs: 'San Vicente y las Granadinas',
    inputLimit: '7'
  },
  {
    label: '+1787',
    value: 1787,
    name: '波多黎各',
    nameHant: '波多黎各',
    nameEn: 'Puerto Rico',
    nameEs: 'Puerto Rico',
    inputLimit: '7'
  },
  {
    label: '+1809',
    value: 1809,
    name: '多米尼加共和国',
    nameHant: '多米尼加共和國',
    nameEn: 'Dominican Republic',
    nameEs: 'República Dominicana',
    inputLimit: '7'
  },
  {
    label: '+1829',
    value: 1829,
    name: '多米尼加共和国',
    nameHant: '多米尼加共和國',
    nameEn: 'Dominican Republic',
    nameEs: 'República Dominicana',
    inputLimit: '7'
  },
  {
    label: '+1849',
    value: 1849,
    name: '多米尼加共和国',
    nameHant: '多米尼加共和國',
    nameEn: 'Dominican Republic',
    nameEs: 'República Dominicana',
    inputLimit: '7'
  },
  {
    label: '+1868',
    value: 1868,
    name: '特立尼达和多巴哥',
    nameHant: '特立尼達和多巴哥',
    nameEn: 'Trinidad and Tobago',
    nameEs: 'Trinidad y Tobago',
    inputLimit: '7'
  },
  {
    label: '+1869',
    value: 1869,
    name: '圣基茨和尼维斯',
    nameHant: '聖基茨和尼維斯',
    nameEn: 'Saint Kitts and Nevis',
    nameEs: 'San Cristóbal y Nieves',
    inputLimit: '7'
  },
  {
    label: '+1876',
    value: 1876,
    name: '牙买加',
    nameHant: '牙買加',
    nameEn: 'Jamaica',
    nameEs: 'Jamaica',
    inputLimit: '7'
  }
]
