<template>
  <div
    class="phone-check-dialog"
  >
    <div class="wrapper">

      <div>
        <div class="dialog-header padding-horizontal-20">
          <div class="dialog-title">
            {{ $t('手机号验证') }}
          </div>
          <div
            class="close-icon"
            @click="close"
          >
          </div>
        </div>
      </div>
      <div class="phone-number-tips padding-horizontal-20">
        {{ $t('您的手机号') }}：{{ phone }}
      </div>
      <div class="input-area padding-horizontal-20">
        <input
          v-model.trim="checkCode"
          class="input"
          maxlength="6"
          :placeholder="$t('请输入手机验证码')"
          type="text"
        >
        <div
          :disabled="isCountDowning"
          class="getcode-btn"
          @click="start()"
        >
          <span v-show="!isCountDowning">{{ isCountDowned ? $t('重新获取验证码') : $t('获取验证码') }}</span>
          <span
            v-show="isCountDowning"
            class="counting-container"
          >
            {{ time }}s

          </span>
        </div>
      </div>
      <div class="confirm-btn-container padding-horizontal-20">
        <div
          class="confirm-btn"
          @click="confirm"
        >
          {{ $t('确定') }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import api from '@/api/index'
export default {
  name: 'CheckPhoneMessageDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    pageMode: {
      type: String,
      default: 'pc'
    },
    phone: {
      type: String,
      default: ''
    },
    geeInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    countryCode: {
      type: String,
      default: '+86'
    },
    channelNum: {
      type: String,
      default: ''
    },
    pageType: {
      type: String,
      default: '4'
    }
  },
  data () {
    return {
      // 是否正在倒计时
      isCountDowning: false,
      // 是否已经倒计时过
      isCountDowned: false,
      // 验证码
      checkCode: '',
      token: '',
      validTimer: null,
      countDownTimer: null,
      time: 60,
      isValid: true
    }
  },
  computed: {
    isMobile () {
      return this.pageMode === 'mobile'
    }
  },
  watch: {
    dialogVisible (newVal) {
      if (!newVal) {
        this.isCountDowning = false
        this.isCountDowned = false
        this.token = ''
        if (this.validTimer) {
          clearInterval(this.validTimer)
          this.isValid = true
        }
      }
    }
  },
  beforeDestroy () {
    try {
      clearInterval(this.timer)
      this.timer = null
    } catch (_) {
      console.log(_)
    }
  },
  methods: {
    getContainer () {
      return document.body
    },
    close () {
      this.$emit('close')
    },
    confirm () {
      if (this.checkCode) {
        this.$emit('confirm', this.checkCode)
      } else {
        this.$toast({
          message: this.$t('请输入手机验证码')
        })
      }
    },
    start () {
      if (this.isCountDowning) return

      this.isCountDowning = true
      const { countryCode, phone, channelNum, pageType } = this.$props
      if (countryCode === '' || countryCode === 'none') {
        this.$toast({
          message: this.$t('请选择国别码')
        })
        return
      }
      const sendMessageOptions = {
        countryCode: countryCode,
        phone: phone,
        channelNum: channelNum,
        pageType
      }
      if (this.geeInfo && Object.keys(this.geeInfo).length) {
        // eslint-disable-next-line camelcase
        const { geetest_challenge, geetest_seccode, geetest_validate } = this.geeInfo
        // eslint-disable-next-line camelcase
        sendMessageOptions.challenge = geetest_challenge
        // eslint-disable-next-line camelcase
        sendMessageOptions.seccode = geetest_seccode
        // eslint-disable-next-line camelcase
        sendMessageOptions.validate = geetest_validate
      }

      if (this.isCountDowned) {
        sendMessageOptions.token = this.token
      }
      if (this.isValid) {
        api.sendWithGee(sendMessageOptions).then((res) => {
          console.log(res)
          if (res) {
            this.token = res.token
            this.validTimer = setTimeout(() => {
              this.isValid = false
            }, 5 * 60 * 1000)
            this.countDownTimer = setInterval(() => {
              const v = ~~this.time - 1
              this.time = v
              if (this.time <= 0) {
                clearInterval(this.countDownTimer)
                this.countDownTimer = null
                this.finish()
              }
            }, 1000)
          }
        }).catch(err => {
          this.isCountDowning = false
          console.error(err)
        })
      } else {
        this.$toast({
          message: this.$t('安全验证失败，请刷新网页')
        })
      }
    },
    finish () {
      this.time = 60
      this.isCountDowning = false
      this.isCountDowned = true
      this.isValid = true
    }
  }
}
</script>

<style lang="less">
.phone-check-dialog {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  .wrapper {
    position: relative;
    width: 380px;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 20px 20px;
    .padding-horizontal-20 {
      padding: 0 20px;
    }
    .dialog-header {
      position: relative;
      .dialog-title {
        font-size: 18px;
      }

      .close-icon {
        position: absolute;
        right: 10px;
        top: -20px;
        width: 20px;
        height: 20px;
        background: url('~assets/images/dialog/icon-close.png') no-repeat
          center/cover;

        cursor: pointer;

        &:hover {
          background: url('~assets/images/dialog/icon-close-hover.png')
            no-repeat center/cover;
        }
      }
    }

    .phone-number-tips {
      color: #182641;
      font-size: 16px;
      margin-top: 20px;
    }
    .input-area {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .input {
        height: 40px;
        flex: 1;
        font-size: 14px;
        padding-left: 14px;
        border-radius: 5px;
        color: #bcc3d6;
        background-color: #f3f3f9;
        &::placeholder {
          font-size: 14px;
        }
      }

      .getcode-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 80px;
        padding-left: 15px;
        padding-right: 15px;
        height: 40px;
        font-size: 10px;
        margin-left: 10px;
        background: #ffffff;
        color: #bcc3d6;
        border-radius: 4px;
        border: 1px solid #d7dbec;
        cursor: pointer;
        .counting-container {
          display: flex;
          justify-content: center;
        }
      }
    }

    .confirm-btn-container {
      margin: 30px 0;
      text-align: center;
      .confirm-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        border: 1px solid #ddd;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .phone-check-dialog {
    .confirm-btn-container {
      .confirm-btn {
        width: 100%;
      }
    }
  }
}
</style>
